import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})
export default class InviteConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public checked: boolean = APP_CONST.FALSE;

    @Prop()
    fullName!: '';

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    close(){
        this.$emit('close', {id:'invitation-confirmation'});
    }

    addStaff() {
        this.close();
        this.$emit('openStaffForm');
    }

    checkBoxHandler(ev: boolean) {
        if(ev) {
                APP_UTILITIES.setCookie('invitation-confirmation', 'true', 1);
        } else {
            if (APP_UTILITIES.getCookie('invitation-confirmation')){
                APP_UTILITIES.deleteCookie('invitation-confirmation');
            }
        }
    }
}